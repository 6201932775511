import throttle from 'lodash.throttle'
import * as React from 'react'

import { Tooltip, TooltipTrigger, useTooltipState } from '../../main/components/tooltip'
import { Heading } from '../../main/components/typography'
import { useResizeObserver } from '../../main/hooks/use-resize-observer'
import { MarketBadge, MarketBadgeProps, getMarketBadgeText, getMarketBadgeType } from './market-badge'
import { TimeFormatType, getDate, getFormattedDateString } from './utils'

const UPDATE_INTERVAL = 10000

enum TimeBreakpointMode {
  container,
  window,
}

export interface TimeBreakpoint {
  minWidth: number
  marketBadgeText: boolean
  timeFormat: TimeFormatType
}

const BREAKPOINTS: TimeBreakpoint[] = [
  { minWidth: -Infinity, marketBadgeText: false, timeFormat: 'timeOnly' },
  { minWidth: 140, marketBadgeText: false, timeFormat: 'short' },
  { minWidth: 180, marketBadgeText: false, timeFormat: 'long' },
  { minWidth: 255, marketBadgeText: true, timeFormat: 'long' },
]

function getTimeState(fixedTime?: string | Date, hasUserPremium: boolean = FinvizSettings.hasUserPremium) {
  const time = getDate(fixedTime)
  const badge = getMarketBadgeType(time, hasUserPremium)

  return { time, badge }
}

function TooltipBadge(props: MarketBadgeProps & { time: Date }) {
  const state = useTooltipState({ placement: 'bottom' })
  const badge = getMarketBadgeText(props.badge, props.time)

  if (!badge) return null

  return (
    <>
      <TooltipTrigger state={state} aria-label={badge.label}>
        <div className="flex h-5 w-5 cursor-help items-center justify-center">
          <MarketBadge {...props} />
        </div>
      </TooltipTrigger>
      <Tooltip state={state} className="px-2 text-xs">
        <Heading level={6} className="my-0">
          {badge.label}
        </Heading>
        {/* {badge.description && <Paragraph size="small">{badge.description}</Paragraph>} */}
      </Tooltip>
    </>
  )
}

function StaticBadge(props: MarketBadgeProps & { time: Date }) {
  const badge = getMarketBadgeText(props.badge, props.time)

  if (!badge) return null

  return (
    <>
      <MarketBadge {...props} className="mr-2" />
      <span>{`${badge.label} · `}</span>
    </>
  )
}

/**
 * Find matching breakpoint or return the last (largest) one
 */
function findMatchingBreakpoint(compareWidth: number | undefined, breakpoints: TimeBreakpoint[]) {
  if (typeof compareWidth !== 'number') return breakpoints[breakpoints.length - 1]

  const matchingBreakpoints = breakpoints.filter((breakpoint) => compareWidth >= breakpoint.minWidth)

  return matchingBreakpoints.pop() ?? breakpoints[breakpoints.length - 1]
}

function getCurrentWidth(mode: TimeBreakpointMode, element: HTMLElement | null) {
  return mode === TimeBreakpointMode.window ? window.innerWidth : (element?.clientWidth ?? 0)
}

interface TimeProps extends React.HTMLProps<HTMLDivElement> {
  /**
   * Set custom time to display
   */
  fixedTime?: string | Date

  /**
   * What to measure the breakpoints to
   * - `container` parent element
   * - `window` window
   *
   * @default "container"
   */
  breakpointMode?: keyof typeof TimeBreakpointMode

  /**
   * Defines what information is visible at which breakpoint. Local mode measures
   * the component wrapper div and global mode measures window width. The definitions
   * must be ascending in width
   */
  breakpoints?: TimeBreakpoint[]
}

export function Time({
  className,
  fixedTime,
  breakpointMode = 'container',
  breakpoints = BREAKPOINTS,
  ...props
}: TimeProps) {
  const ResizeObserver = useResizeObserver()
  const rootRef = React.useRef<HTMLDivElement>(null)
  const [state, setState] = React.useState(getTimeState(fixedTime))
  const [currentBreakpoint, setBreakpoint] = React.useState<TimeBreakpoint | null>(null)

  React.useEffect(() => {
    const interval = setInterval(() => setState(getTimeState(fixedTime)), UPDATE_INTERVAL)
    return () => clearInterval(interval)
  }, [fixedTime])

  React.useEffect(() => {
    if (!ResizeObserver || !rootRef.current) return

    if (!currentBreakpoint) {
      const currentWidth = getCurrentWidth(TimeBreakpointMode[breakpointMode], rootRef.current)
      setBreakpoint(findMatchingBreakpoint(currentWidth, breakpoints))
    }

    const handleResize = throttle(() => {
      const currentWidth = getCurrentWidth(TimeBreakpointMode[breakpointMode], rootRef.current)
      const foundBreakpoint = findMatchingBreakpoint(currentWidth, breakpoints)

      if (foundBreakpoint !== currentBreakpoint) setBreakpoint(foundBreakpoint)
    }, 150)

    const observer = new ResizeObserver((entries) => {
      window.requestAnimationFrame(() => {
        if (Array.isArray(entries) && entries.length > 0) handleResize()
      })
    })

    observer.observe(rootRef.current)
    window.addEventListener('resize', handleResize)

    return () => {
      observer.disconnect()
      window.removeEventListener('resize', handleResize)
    }
  }, [ResizeObserver, currentBreakpoint, breakpoints, breakpointMode])

  return (
    <div ref={rootRef} {...props} className={className}>
      {currentBreakpoint && (
        <div className="flex items-center justify-end overflow-hidden whitespace-nowrap">
          {currentBreakpoint.marketBadgeText ? (
            <StaticBadge time={state.time} badge={state.badge} />
          ) : (
            <TooltipBadge time={state.time} badge={state.badge} />
          )}
          <span>{getFormattedDateString(state.time, currentBreakpoint.timeFormat)}</span>
        </div>
      )}
    </div>
  )
}
