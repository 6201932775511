// OS Matches
const matchesAndroid = navigator.userAgent.match(/Android/i)
const matchesWebOS = navigator.userAgent.match(/webOS/i)
const matchesIPhone = navigator.userAgent.match(/iPhone/i)
const matchesiPad = navigator.userAgent.match(/iPad/i)
const matchesIPod = navigator.userAgent.match(/iPod/i)
const matchesBlackBerry = navigator.userAgent.match(/BlackBerry/i)
const matchesWindowsPhone = navigator.userAgent.match(/Windows Phone/i)
const matchesIOSLike = navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints > 0

// Browser Matches
const matchesFirefox = !!navigator.userAgent.match(/firefox/i)
const matchesSafariDesktopLike =
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent) && navigator.maxTouchPoints === 0

const isMobileDevice =
  matchesAndroid ||
  matchesWebOS ||
  matchesIPhone ||
  matchesiPad ||
  matchesIPod ||
  matchesBlackBerry ||
  matchesWindowsPhone ||
  matchesIOSLike

export function isMobile() {
  return !!isMobileDevice
}

export function isIphone() {
  return !!(matchesIPhone && !matchesiPad)
}

export function isIpad() {
  return (matchesIOSLike && !matchesIPhone && !matchesIPod) || matchesiPad
}

export function isSafariDesktop() {
  return matchesSafariDesktopLike
}

export function isIos() {
  return !!matchesIOSLike && !isSafariDesktop()
}

export function isFirefox() {
  return matchesFirefox
}

export function isFirefoxDesktop() {
  return isFirefox() && !isMobile()
}
