import * as React from 'react'

import { loadResizeObserverPolyfill } from '../util'

export function useResizeObserver() {
  const controller = React.useRef('AbortController' in window ? new AbortController() : undefined)
  const [ResizeObserver, setResizeObserver] = React.useState<typeof window.ResizeObserver | undefined>(
    () => window.ResizeObserver
  )

  React.useEffect(() => {
    if (typeof ResizeObserver === 'undefined') {
      loadResizeObserverPolyfill().then((polyfill) => {
        if (!controller.current?.signal.aborted) {
          setResizeObserver(() => polyfill)
        }
      })
    }
  }, [ResizeObserver])

  // Cleanup effect to abort the controller
  React.useEffect(() => () => controller.current?.abort(), [])

  return ResizeObserver
}
