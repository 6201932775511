import * as Ariakit from '@ariakit/react'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { SearchProps } from './search'

interface Props extends Pick<SearchProps, 'state' | 'items'> {
  children: React.ReactNode
}

function getSelectedItem({
  value,
  items,
}: {
  value?: Ariakit.ComboboxStoreProps['selectedValue']
  items: SearchProps['items']
}) {
  return items?.find((item) => item.value === value)
}

export function SearchNavigationWrapperInRouterContext({ state, children, items }: Props) {
  const navigate = useNavigate()
  const selectedValue = state.useState('selectedValue')
  React.useEffect(() => {
    const { href, reloadDocument } = getSelectedItem({ value: selectedValue, items }) ?? {}
    if (href) {
      if (reloadDocument) {
        window.location.href = href
      } else {
        navigate(href)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue])

  return children
}

export function SearchNavigationWrapperOutsideRouterContext({ state, children, items }: Props) {
  const selectedValue = state.useState('selectedValue')
  React.useEffect(() => {
    const href = getSelectedItem({ value: selectedValue, items })?.href

    if (href) {
      window.location.href = href
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue])

  return children
}
