import Notice from './notice'

export default function ScamNotice() {
  return (
    <Notice id="scam">
      We have been notified by several users from Russia and other Russian-speaking countries, that they were contacted
      by various individuals by phone, pretending to be employees of FINVIZ.com, offering fraudulent investment
      services. Please note that these activities are not related to FINVIZ.com in any way, we do not have any employees
      or partners in this region.
      <br />
      If you notice such activity, please send us an email at{' '}
      <a className="tab-link" href="mailto:support@finviz.com">
        support@finviz.com
      </a>
      . Thank you.
      <br />
      <br />В последнее время участились случаи обращений пользователей из России и русскоговорящих регионах, которые
      утверждали, что с ними связывались по телефону мошеннические лица, выдававшие себя за сотрудников компании
      FINVIZ.com, предлагая мошеннические инвестиционные услуги. Обратите внимание, что эти мероприятия не связаны с
      FINVIZ.com в любом случае, у нас нет никаких сотрудников или партнеров в этом регионе. Если вы заметили такую
      активность, пожалуйста, отправьте нам электронное письмо по адресу{' '}
      <a className="tab-link" href="mailto:support@finviz.com">
        support@finviz.com
      </a>
      . Спасибо.
    </Notice>
  )
}
