import { useMemo } from 'react'

interface HighlightProps {
  /**
   * Search string that will be highlighted
   */
  highlight: string
  /**
   * Text to be highlighted
   */
  text: string
}

export function Highlight({ highlight, text }: HighlightProps) {
  const index = text.toLowerCase().indexOf(highlight.toLowerCase())

  const processedText = useMemo(
    () => ({
      before: text.substring(0, index),
      highlight: text.substring(index, index + highlight.length),
      after: text.substring(index + highlight.length),
    }),
    [highlight.length, index, text]
  )

  if (index < 0 || highlight.length < 1) return <span>{text}</span>

  return (
    <>
      <span className="relative z-10">{processedText.before}</span>
      {!!processedText.highlight.length && (
        <span className="rounded-sm bg-yellow-100 text-yellow-800 dark:bg-transparent dark:text-orange-300">
          {processedText.highlight}
        </span>
      )}
      <span className="relative z-10">{processedText.after}</span>
    </>
  )
}
