import classnames from 'classnames'
import * as React from 'react'

import { TooltipColor } from './types'

export interface TooltipBoxProps extends Omit<React.HTMLProps<HTMLDivElement>, 'ref'> {
  /**
   * Color of the tooltip. If se to `none`, the tooltip will have default color
   * based on light/dark theme
   *
   * @default 'none'
   */
  color?: keyof typeof TooltipColor
}

export const TooltipBox = React.forwardRef(function TooltipBox(
  { color = 'none', ...props }: TooltipBoxProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const tooltipColor = TooltipColor[color]

  return (
    <div
      {...props}
      ref={ref}
      className={classnames(props.className, 'rounded px-2 py-1 text-2xs', {
        'bg-gray-700 text-gray-50 dark:bg-gray-300 dark:text-gray-700': tooltipColor === TooltipColor.none,
        'bg-red-400 text-gray-50': tooltipColor === TooltipColor.red,
        'bg-amber-600 text-gray-50': tooltipColor === TooltipColor.orange,
        'bg-blue-400 text-gray-50': tooltipColor === TooltipColor.blue,
        'bg-emerald-500 text-gray-50': tooltipColor === TooltipColor.green,
        'bg-gray-700 text-white': tooltipColor === TooltipColor.dark,
      })}
    >
      {props.children}
    </div>
  )
})
