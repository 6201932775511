import React from 'react'

import { PopoverPlacement } from '../components'
import { useZIndex } from '../components/dialog/hooks'

function getPlacementZIndex(placement: PopoverPlacement) {
  return placement!.startsWith('top') ? 'z-dialog' : 'z-50'
}

function changeZIndex(element: HTMLElement | null, placement: PopoverPlacement, computedZIndex = '') {
  if (!element) return
  element.style.removeProperty('z-index')
  element.classList.remove('z-50', 'z-dialog', computedZIndex)
  element.classList.add(getPlacementZIndex(placement))
}

export function usePopoverPlacement(context: any) {
  const popoverElement: HTMLDivElement | null = context.useState('popoverElement')
  const placement: PopoverPlacement = context.useState('currentPlacement')
  const contextZIndex = useZIndex(null)
  const computedZIndex = getPlacementZIndex(placement)

  const updatePosition = React.useCallback(
    (props: { updatePosition: () => Promise<void> }) => {
      if (!popoverElement) return

      const overlayElement = popoverElement?.previousElementSibling as HTMLElement | null

      if (!contextZIndex) {
        changeZIndex(popoverElement, placement, computedZIndex)
        if (overlayElement && overlayElement.dataset.backdrop) changeZIndex(overlayElement, placement, computedZIndex)
      }

      props.updatePosition()
    },
    [popoverElement, contextZIndex, computedZIndex, placement]
  )

  return {
    zIndex: contextZIndex ?? computedZIndex,
    placement,
    updatePosition,
  }
}
