function getSanitizedSingleTicker(rawTicker: string, specialTickers: string[] = []) {
  return rawTicker
    .replace(/\.+/gi, '-')
    .split('-')
    .map((x) => {
      if (specialTickers.includes(x.toUpperCase())) return x
      return x.replace(/([^a-z0-9@-])+/gi, '')
    })
    .filter((x) => !!x)
    .join('-')
    .toUpperCase()
}

export function getSanitizedTicker(rawTicker: string, isMulti = false, specialTickers: string[] = []) {
  if (isMulti) {
    return rawTicker
      .replace(/ /g, ',')
      .split(',')
      .map((x) => getSanitizedSingleTicker(x, specialTickers))
      .filter((x) => !!x)
      .join(',')
  }

  return getSanitizedSingleTicker(rawTicker, specialTickers)
}
