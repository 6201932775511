import * as Ariakit from '@ariakit/react'

/**
 * Hook which provides state to tooltips
 */
export function useTooltipState({ showTimeout = 0, hideTimeout = 0, ...settings }: Ariakit.TooltipStoreProps = {}) {
  return Ariakit.useTooltipStore({
    ...settings,
    showTimeout,
    hideTimeout,
  })
}

export type TooltipStateReturn = ReturnType<typeof useTooltipState>
