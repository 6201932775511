import * as Ariakit from '@ariakit/react'
import * as React from 'react'

import { TooltipStateReturn } from './hooks'

interface TooltipButtonProps extends Omit<Ariakit.TooltipAnchorProps, 'store'> {
  // State is intentionally not marked as optional so you get error when you don’t provide it
  /**
   * Returned state from `useTooltipState`. Set `undefined` if you use a `TooltipProvider` wrapper (eg. TooltipInput)
   */
  state: TooltipStateReturn | undefined

  /**
   * Hides tooltip on trigger click
   *
   * @default false
   */
  isHideOnClick?: boolean

  /**
   * Whether or not the tooltip should be visible when user hovers over the trigger element
   *
   * @default true
   */
  showOnHover?: boolean

  /**
   * Whether or not the tooltip should hide when the element is blurred
   */
  hideOnBlur?: boolean
}
/**
 * The trigger button component which handles ref & focus return, etc.
 */
export const TooltipTrigger = React.forwardRef(
  (
    {
      state,
      isHideOnClick,
      showOnHover = true,
      hideOnBlur,
      onClick,
      onMouseEnter,
      onMouseLeave,
      ...props
    }: TooltipButtonProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const context = Ariakit.useTooltipContext()
    const stateObject = state ?? context

    return (
      <Ariakit.TooltipAnchor
        ref={ref}
        store={stateObject}
        onClick={(ev) => {
          if (isHideOnClick) stateObject?.hide()
          onClick?.(ev)
        }}
        showOnHover={showOnHover}
        onMouseEnter={(ev) => {
          if (showOnHover === false) ev.preventDefault()
          onMouseEnter?.(ev)
        }}
        onMouseLeave={(ev) => {
          if (showOnHover === false) ev.preventDefault()
          onMouseLeave?.(ev)
        }}
        onBlur={() => {
          if (hideOnBlur) stateObject?.hide()
        }}
        {...props}
      >
        {props.children}
      </Ariakit.TooltipAnchor>
    )
  }
)
