import { getSanitizedTicker } from '../../../app/shared/ticker-sanitizer'
import { link } from '../../services/routing'
import { Instrument } from '../../types'
import { cleanTicker, getInstrumentForTicker } from '../../util'

export function getRedirectForTicker(tickerRaw: string) {
  const ticker = getSanitizedTicker(tickerRaw, true)
  const instrument = cleanTicker(ticker) ? getInstrumentForTicker(ticker) : null
  let redirectLink = ''

  switch (instrument) {
    case Instrument.Stock:
      redirectLink = link.toQuote(ticker)
      break
    case Instrument.Forex:
      redirectLink = link.toForexChartDetail(ticker)
      break
    case Instrument.Futures:
      redirectLink = link.toFuturesChartDetail(ticker)
      break
    case Instrument.Crypto:
      redirectLink = link.toCryptoChartDetail(ticker)
      break
    default:
      return link.toSearch(ticker)
  }

  return redirectLink + (FinvizSettings.quoteSearchExt ?? '')
}
