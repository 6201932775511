import classnames from 'classnames'

import { isAftermarket, isHoliday, isPremarket } from './utils'

export enum MarketBadgeType {
  None,
  Open,
  Closed,
  Premarket,
  Aftermarket,
}

export function getMarketBadgeText(type: MarketBadgeType, _date?: Date) {
  switch (type) {
    case MarketBadgeType.Open:
      return {
        label: 'Markets Open',
      }
    case MarketBadgeType.Closed:
      // const holiday = getHoliday(date)

      return {
        label: 'Markets Closed',
        // description: holiday && (
        //   <>
        //     The markets observe U.S. holidays and are closed on
        //     <br />
        //     {holiday.label}
        //   </>
        // ),
      }
    case MarketBadgeType.Premarket:
      return {
        label: 'Premarket',
        // description: (
        //   <>
        //     An additional time window when trading is possible.
        //     <br />
        //     We report it as the time between 7:00 AM and 9:30 AM
        //   </>
        // ),
      }
    case MarketBadgeType.Aftermarket:
      return {
        label: 'Aftermarket',
        // description: (
        //   <>
        //     An additional time window when trading is still possible.
        //     <br />
        //     We report it as the time between 4:00 PM and 6:30 PM
        //   </>
        // ),
      }
  }
}

export function getMarketBadgeType(time: Date, hasUserPremium?: boolean) {
  if (isHoliday(time)) return MarketBadgeType.Closed
  if (isPremarket(time, hasUserPremium)) return MarketBadgeType.Premarket
  if (isAftermarket(time, hasUserPremium)) return MarketBadgeType.Aftermarket
  // if (isMarketOpen(time, hasUserPremium)) return MarketBadgeType.Open

  return MarketBadgeType.None
}

export interface MarketBadgeProps {
  badge: MarketBadgeType
  className?: string
}

export function MarketBadge({ badge, className }: MarketBadgeProps) {
  if (badge === MarketBadgeType.None) return null

  return (
    <div
      className={classnames('h-1.5 w-1.5 shrink-0 rounded-full', className, {
        'bg-green-400': badge === MarketBadgeType.Open,
        'bg-yellow-200': [MarketBadgeType.Premarket, MarketBadgeType.Aftermarket].includes(badge),
        'bg-red-400': badge === MarketBadgeType.Closed,
      })}
    />
  )
}
