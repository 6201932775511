import * as Ariakit from '@ariakit/react'
import classnames from 'classnames'
import * as React from 'react'

import { isIos } from '../../../app/shared/isMobile'
import { usePopoverPlacement } from '../../hooks/usePopoverPlacement'
import { TooltipStateReturn } from './hooks'
import { TooltipArrow } from './tooltip-arrow'
import { TooltipBox, TooltipBoxProps } from './tooltip-box'

const isIosBrowser = isIos()

const TOOLTIP_WRAPPER_PROPS = {
  className: 'pointer-events-none',
}

export interface TooltipProps
  extends TooltipBoxProps,
    Pick<Ariakit.TooltipProps, 'gutter' | 'hideOnHoverOutside' | 'onClose'> {
  // State is intentionally not marked as optional so you get error when you don’t provide it
  /**
   * Returned state from `useTooltipState`. Set `undefined` if you use a `PopoverProvider` wrapper (eg. TooltipInput)
   */
  state: TooltipStateReturn | undefined

  /**
   * Determines whether or not the tooltip receives mouse events
   *
   * @default true
   */
  isPointerEventsEnabled?: boolean
}

export function Tooltip({
  state,
  children,
  gutter = 4,
  hideOnHoverOutside = true,
  isPointerEventsEnabled = true,
  color = 'none',
  onClose,
  ...props
}: React.PropsWithChildren<TooltipProps>) {
  const context = Ariakit.useTooltipContext()
  const stateObject = state ?? context
  const { placement, zIndex, updatePosition } = usePopoverPlacement(stateObject)

  return (
    <Ariakit.Tooltip
      store={state}
      focusable={false}
      gutter={gutter}
      wrapperProps={isPointerEventsEnabled ? undefined : TOOLTIP_WRAPPER_PROPS}
      hideOnHoverOutside={hideOnHoverOutside}
      onClose={onClose}
      // On iOS depending on composition TooltipTrigger doesn't work properly if Tooltip isn't always rendered
      // and causes bug on interactive elements with tooltip like selects, buttons, etc. when on first click
      // only tooltip appears but select, button or etc. doesn't receive event so it won't open, also on first click
      // in iOS safari onClick event isn't fired no TooltipTrigger, just onPointerDown, and thus further breaking logic
      // because our custom callback which are placed in onClick aren't invoked, after debugging we think that this
      // is bug caused by combination of ariakit behaviour when <Tooltip> isn't always rendered and way of events
      // firing by iOS Safari
      unmountOnHide={!isIosBrowser}
      className={classnames(
        zIndex,
        'cursor-default opacity-0 outline-none transition duration-[250ms] will-change-transform data-[enter]:translate-x-0 data-[enter]:translate-y-0 data-[enter]:opacity-100',
        {
          'motion-safe:translate-y-1': placement?.startsWith('top'),
          'motion-safe:-translate-x-1': placement?.startsWith('right'),
          'motion-safe:-translate-y-1': placement?.startsWith('bottom'),
          'motion-safe:translate-x-1': placement?.startsWith('left'),
        }
      )}
      // Update zIndex when popover position changes
      updatePosition={updatePosition}
    >
      <TooltipBox {...props} color={color} data-placement={placement}>
        <Ariakit.TooltipArrow store={state} size={11}>
          <TooltipArrow placement={placement} color={color} />
        </Ariakit.TooltipArrow>
        {children}
      </TooltipBox>
    </Ariakit.Tooltip>
  )
}
