import { DefaultOptions, MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import * as React from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { DefaultErrorBoundary } from '../../../main/components/error-view'

interface QueryClientConfig {
  queryCache?: QueryCache
  mutationCache?: MutationCache
  defaultOptions?: DefaultOptions
}

interface QueryContextProps {
  queryOptions?: QueryClientConfig
}

export function getQueryClient(queryOptions?: QueryClientConfig) {
  return new QueryClient({
    defaultOptions: {
      queries: {
        useErrorBoundary: true,
        refetchOnWindowFocus: false,
        ...queryOptions?.defaultOptions?.queries,
      },
      ...queryOptions?.defaultOptions,
    },
    ...queryOptions,
  })
}

export function QueryClientContext({ children, queryOptions }: React.PropsWithChildren<QueryContextProps>) {
  const client = React.useRef(getQueryClient(queryOptions))

  return (
    <QueryClientProvider client={client.current}>
      <ErrorBoundary FallbackComponent={DefaultErrorBoundary}>{children}</ErrorBoundary>
    </QueryClientProvider>
  )
}

export function withQueryClientContext<Props>(
  Component: React.ComponentType<Props>,
  config?: QueryClientConfig
): React.ComponentType<Props> {
  const hocComponent = (props: Props) => (
    <QueryClientContext queryOptions={config}>
      <Component {...(props as Props & React.Attributes)} />
    </QueryClientContext>
  )

  hocComponent.displayName = `withQueryClientContext(${Component.displayName ?? 'Component'})`

  return hocComponent
}
