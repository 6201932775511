import * as Sentry from '@sentry/browser'
import { ReactNode, useEffect } from 'react'
import { FallbackProps } from 'react-error-boundary'
import { useRouteError } from 'react-router-dom'

import { link } from '../services/routing'
import { Button } from './button'
import { Heading, Paragraph } from './typography'

interface ErrorViewProps {
  /**
   * Title of the message
   */
  title?: ReactNode

  /**
   * Body text
   */
  message?: ReactNode

  /**
   * Show contact support text under the message
   *
   * @default true
   */
  showContact?: boolean

  /**
   * Show refresh page button under the text
   *
   * @default true
   */
  showRefresh?: boolean
}

export function ErrorView({ title, message, showContact = true, showRefresh = true }: ErrorViewProps) {
  return (
    <div className="flex h-full w-full select-text flex-col items-center justify-center" data-testid="error-view">
      <Heading level={2}>{title}</Heading>
      <Paragraph className="mt-2 w-96 text-center">{message}</Paragraph>

      {showContact && (
        <Paragraph className="mt-4 w-96 text-center">
          Our development team has been notified of this. If the error persists, please{' '}
          <a href={link.toContact()} className="tab-link text-link hover:underline">
            contact support
          </a>
          .
        </Paragraph>
      )}

      {showRefresh && (
        <Button
          as="a"
          className="mt-6"
          href={window.location.origin + window.location.pathname + window.location.search}
        >
          Refresh page
        </Button>
      )}
    </div>
  )
}

export function DefaultErrorBoundary({
  error,
  title = 'Application error',
  message = 'We’re sorry but an unknown error occured.',
  ...props
}: Partial<FallbackProps & ErrorViewProps>) {
  useEffect(() => {
    // Ignore webpack chunk loading errors
    if (error?.name === 'ChunkLoadError') return
    // Otherwise log to Sentry
    Sentry.captureException(error, { level: 'fatal' })
    // so we can see error in logs
    console.error('ErrorBoundary:', JSON.stringify(error))
  }, [error])

  return <ErrorView title={title} message={message} {...props} />
}

export function RouterErrorElement() {
  const error = useRouteError() as Error
  return <DefaultErrorBoundary error={error} />
}
