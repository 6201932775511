import * as Ariakit from '@ariakit/react'
import * as React from 'react'

import { Centered } from '../centered'
import { Spinner } from '../spinner'
import { SearchListItem } from './constants'
import { SearchItem } from './search-item'

interface SearchListProps {
  /**
   * Ariakit combobox store for managing the search list state
   */
  state: Ariakit.ComboboxStore

  /**
   * Array of items to display in the search list
   */
  items?: SearchListItem[]

  /**
   * Custom footer content shown at the bottom of the search list
   */
  footer?: React.ReactNode

  /**
   * Whether the list is currently loading
   *
   * @default false
   */
  isLoading?: boolean
}

export function SearchList({
  state,
  children,
  items,
  footer,
  isLoading = false,
}: React.PropsWithChildren<SearchListProps>) {
  const isOpen = state.useState('open')
  const hasContent = React.Children.count(children) > 0 || (items?.length ?? 0) > 0 || !!footer || isLoading
  const { hide } = state

  React.useEffect(() => {
    if (!hasContent && isOpen) {
      hide()
    }
  }, [hide, hasContent, isOpen])

  if (isLoading) {
    return (
      <Centered className="h-52 flex-1">
        <Spinner />
      </Centered>
    )
  }

  return (
    <div className="flex flex-1 flex-col space-y-2 overflow-hidden">
      <div className="flex grow flex-col overflow-auto">
        {children ?? items?.map((item) => <SearchItem state={state} key={item.value} item={item} />)}
      </div>
      {footer}
    </div>
  )
}
