import classnames from 'classnames'
import * as React from 'react'

export enum BoxRounding {
  none = '',
  regular = 'rounded-md', // 6px
}

export enum BoxTheme {
  none = '',
  light = 'bg-white dark:bg-gray-800 border border-gray-100 dark:border-gray-600',
  dark = 'bg-gray-800 border border-gray-600',
}

export interface BoxProps extends Omit<React.HTMLProps<HTMLDivElement>, 'ref'> {
  'data-testid'?: string
  rounding?: keyof typeof BoxRounding
  theme?: keyof typeof BoxTheme
}

export const Box = React.forwardRef(function Box(
  { rounding = 'regular', theme = 'light', ...props }: BoxProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <div
      {...props}
      ref={ref}
      className={classnames(
        'shadow-modal dark:shadow-modal-dark',
        props.className,
        BoxRounding[rounding],
        BoxTheme[theme]
      )}
    >
      {props.children}
    </div>
  )
})
