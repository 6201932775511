import * as Sentry from '@sentry/browser'

import { ThrowableError } from '../../../main/services/api'
import { sentryOptions } from './constants'

if ('FinvizSentrySettings' in window && !('sentryDisabled' in window)) {
  Sentry.init({
    ...sentryOptions,
    beforeSend(event: Sentry.ErrorEvent, hint: Sentry.EventHint) {
      const error = hint.originalException as Error | null
      const errorFrames = event.exception?.values?.[0]?.stacktrace?.frames ?? []

      const isIgnoredError = sentryOptions.ignoreStack.some((ignorePattern) => {
        const [ignoreSource, ignoreMessage] = Array.isArray(ignorePattern) ? ignorePattern : [ignorePattern]
        const isIgnoredScript = errorFrames.some((frame) => frame?.filename?.includes(ignoreSource))
        const isIgnoredMessage =
          ignoreMessage && typeof error?.message === 'string' ? error.message.match(ignoreMessage) : false

        return ignoreMessage ? isIgnoredScript && isIgnoredMessage : isIgnoredScript
      })

      if (isIgnoredError) return null

      // Completely ignore 524 errors, track other network errors as `info` if the status is 520+
      const statusCode = error instanceof ThrowableError ? error.status : (event.contexts?.response?.status_code ?? -1)
      if (statusCode === 500) {
        return { ...event, level: 'error', fingerprint: ['500'] }
      } else if (statusCode > 500 && statusCode < 520) {
        return { ...event, level: 'info', fingerprint: ['500+'] }
      } else if (statusCode > 520) {
        // Completely ignore 524 errors
        if (statusCode === 524) return null
        return { ...event, level: 'info', fingerprint: ['520+'] }
      }

      return event
    },
  })

  if (FinvizSentrySettings.id > 0) {
    Sentry.setUser({ id: String(FinvizSentrySettings.id) })
  }

  try {
    Sentry.setTags({
      dpr: window.devicePixelRatio,
      redesign: window.FinvizSettings?.hasRedesignEnabled,
      dark: window.FinvizSettings?.hasDarkTheme,
      innerWidth: window.innerWidth,
      innerHeight: window.innerHeight,
    })
  } catch {}
}

window['Sentry'] = {
  captureException: Sentry.captureException,
  captureMessage: Sentry.captureMessage,
  withScope: Sentry.withScope,
}
