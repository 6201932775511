import * as Ariakit from '@ariakit/react'
import * as React from 'react'

import { isMobile } from '../../../app/shared/isMobile'

export const ZIndexContext = React.createContext<string | null>(null)

export function useZIndex(defaultValue: string | null = 'z-50') {
  return React.useContext(ZIndexContext) ?? defaultValue
}

export type DialogStateProps = Ariakit.DialogStoreProps
export type DialogStateReturn = ReturnType<typeof useDialogState>
/**
 * Hook which provides state to dialogs
 */
export function useDialogState(settings?: DialogStateProps) {
  return Ariakit.useDialogStore(settings)
}

export const DialogDragContext = React.createContext<{
  enabled?: boolean
  handleRef?: React.RefObject<HTMLDivElement>
  boxPosition: { x: number; y: number }
  resetBoxPosition: () => void
  setBoxPosition: React.Dispatch<React.SetStateAction<{ x: number; y: number }>>
}>({
  enabled: false,
  handleRef: undefined,
  boxPosition: { x: 0, y: 0 },
  resetBoxPosition: () => {},
  setBoxPosition: () => {},
})

/**
 * Hook which allows an element on the page to become draggable
 */
export function useDrag(enabled?: boolean) {
  const dragging = React.useRef(false)
  const handleRef = React.useRef<HTMLDivElement>(null)
  const handleElement = handleRef.current

  /**
   * Set box position in both state for the component to re-render and as a ref
   * so that we don’t have to re-run the whole effect on every change
   */
  const [boxPosition, setBoxPosition] = React.useState({ x: 0, y: 0 })
  const boxPositionRef = React.useRef({ x: 0, y: 0 })
  const dragDiff = React.useRef({ x: 0, y: 0 })

  const { current: resetBoxPosition } = React.useRef(() => {
    setBoxPosition({ x: 0, y: 0 })
  })

  React.useEffect(() => {
    boxPositionRef.current = { ...boxPosition }
  }, [boxPosition])

  React.useEffect(() => {
    if (!enabled || !handleElement || isMobile()) return

    const onMouseDown = (ev: MouseEvent) => {
      dragging.current = true

      dragDiff.current = {
        x: ev.pageX - boxPositionRef.current.x - window.scrollX,
        y: ev.pageY - boxPositionRef.current.y - window.scrollY,
      }
    }

    const onMouseUp = () => {
      dragging.current = false
    }

    const onMouseMove = (ev: MouseEvent) => {
      if (!dragging.current) return

      const x = ev.pageX - dragDiff.current.x - window.scrollX
      const y = ev.pageY - dragDiff.current.y - window.scrollY

      setBoxPosition({ x, y })
    }

    handleElement.addEventListener('pointerdown', onMouseDown)
    handleElement.addEventListener('dblclick', resetBoxPosition)
    window.addEventListener('pointerup', onMouseUp)
    document.body.addEventListener('pointermove', onMouseMove)

    return () => {
      handleElement.removeEventListener('pointerdown', onMouseDown)
      handleElement.removeEventListener('dblclick', resetBoxPosition)
      window.removeEventListener('pointerup', onMouseUp)
      document.body.removeEventListener('pointermove', onMouseMove)
    }
  }, [enabled, handleElement, resetBoxPosition])

  return {
    enabled,
    handleRef,
    boxPosition,
    setBoxPosition,
    resetBoxPosition,
  }
}
