import { ReactNode, useRef, useState } from 'react'

import { setCookieRequest } from '../../main/services/api'

interface NoticeProps {
  id: string
  children: ReactNode
}

export default function Notice({ id, children }: NoticeProps) {
  const cookieName = 'notice-' + id
  const [hidden, setHidden] = useState(!!getCookie(cookieName))

  const onClose = useRef(() => {
    setCookieRequest(cookieName).catch(() => {})

    setHidden(true)
  })

  return (
    <div
      style={{
        background: '#fffce5',
        border: '1px solid #fee500',
        color: '#363a46',
        display: hidden ? 'none' : 'block',
        fontFamily: 'Lato',
        fontSize: 13,
        textAlign: 'center',
        margin: '10px auto 10px auto',
        position: 'relative',
        padding: '12px 30px 12px 12px',
        lineHeight: '1.3em',
        width: 'auto',
      }}
    >
      {children}
      <a
        title="Close"
        className="close"
        onClick={onClose.current}
        href="#"
        style={{
          position: 'absolute',
          marginRight: 0,
          right: 10,
          color: '#363a46',
          textDecoration: 'none',
          fontSize: 18,
          top: 'calc(50% - 9px)',
        }}
      >
        ×
      </a>
    </div>
  )
}

function getCookie(name: string) {
  var re = new RegExp('(?:(?:^|.*;\\s*)' + name + '\\s*\\=\\s*([^;]*).*$)|^.*$')
  return document.cookie.replace(re, '$1')
}
