import * as Ariakit from '@ariakit/react'

import { useComboBoxState } from '../combo-box'

export function useSearchComboboxState(props: Ariakit.ComboboxStoreProps<string> = {}) {
  return useComboBoxState({
    placement: 'bottom-start',
    defaultSelectedValue: '',
    ...props,
  })
}
