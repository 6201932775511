export function getParsedCookies(cookieName: string) {
  const parseCookies = document.cookie
    .split(';')
    .map((cookieString) => cookieString.split('='))
    .reduce(
      (acc, v) => {
        acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim())
        return acc
      },
      {} as Record<string, string>
    )

  return cookieName ? parseCookies[cookieName] : parseCookies
}

export function getCookie(name: string) {
  var re = new RegExp('(?:(?:^|.*;\\s*)' + name + '\\s*\\=\\s*([^;]*).*$)|^.*$')
  return document.cookie.replace(re, '$1')
}

export function setCookie(name: string, value: string, expires: Date) {
  var cookie = name + '=' + value + '; expires=' + expires.toUTCString()
  if (/\bfinviz\b/.test(document.location.hostname)) {
    cookie += '; domain=.finviz.com'
  }
  document.cookie = cookie
}
