// Disable rule as the component is external
import * as Ariakit from '@ariakit/react'
import classnames from 'classnames'
import * as React from 'react'

import { BUTTON_DEFAULT_ELEMENT, Button, ButtonProps } from '../button'
import { PropsWithAs } from '../types'

const DEFAULT_ELEMENT = 'button'

export interface ComboBoxItemProps extends ButtonProps {
  /**
   * The returned object from `useComboBoxState`
   */
  state?: Ariakit.ComboboxStore

  /**
   * Value for the combo box item
   */
  value: string
}

function ComboBoxItemButton<TagType extends React.ElementType = typeof DEFAULT_ELEMENT>(
  { as: asComponent, children, 'data-active-item': dataActiveItem, ...props }: PropsWithAs<TagType> & ButtonProps,
  ref: React.ForwardedRef<HTMLElement>
) {
  return (
    <Button
      {...(props as any)}
      ref={ref}
      as={asComponent ?? DEFAULT_ELEMENT}
      active={dataActiveItem === true}
      theme="menuItem"
      type="button"
      align="left"
      contentClass={classnames(props.contentClass, 'font-normal')}
    >
      {children}
    </Button>
  )
}

export const ComboBoxItemButtonWithRef = React.forwardRef(ComboBoxItemButton) as <
  TagType extends React.ElementType = typeof BUTTON_DEFAULT_ELEMENT,
>(
  props: PropsWithAs<TagType> & ButtonProps & { ref?: React.ForwardedRef<HTMLElement> }
) => ReturnType<typeof ComboBoxItemButton>

export function ComboBoxItem<TagType extends React.ElementType = typeof DEFAULT_ELEMENT>({
  as: asComponent,
  state,
  children,
  contentClass,
  ...props
}: PropsWithAs<TagType> & ComboBoxItemProps) {
  return (
    <Ariakit.ComboboxItem
      store={state}
      {...props}
      render={
        <ComboBoxItemButtonWithRef
          as={(asComponent ?? DEFAULT_ELEMENT) as React.ElementType}
          contentClass={contentClass}
        >
          {children}
        </ComboBoxItemButtonWithRef>
      }
    />
  )
}
