import classnames from 'classnames'

interface SpinnerProps {
  /**
   * Spinner as progress bar
   * @default false
   */
  isProgressBar?: boolean

  /**
   * Progress number in percent
   * @default 0
   */
  progress?: number

  /**
   * Spinner box width
   *
   * @default 28
   */
  width?: number
}

export function Spinner({ isProgressBar = false, progress = 0, width = 28 }: SpinnerProps) {
  const radius = (3 * width) / 7
  const strokeWidth = radius / 3
  const circleXY = radius + strokeWidth / 2
  const circumference = radius * 2 * Math.PI

  return (
    <svg
      className={classnames('block shrink-0', { 'animate-spin': !isProgressBar })}
      width={width}
      height={width}
      viewBox={`0 0 ${width} ${width}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className="block origin-center fill-transparent stroke-gray-50 dark:stroke-gray-700"
        style={{ strokeWidth: `${strokeWidth}px` }}
        cx={circleXY}
        cy={circleXY}
        r={radius}
      />
      <circle
        className="block origin-center -rotate-90 transform fill-transparent stroke-blue-400"
        style={{
          strokeDasharray: `${circumference} ${circumference}`,
          strokeDashoffset: `${circumference - ((isProgressBar ? progress : 50) / 100) * circumference}`,
          strokeWidth: `${strokeWidth}px`,
          strokeLinecap: 'round',
        }}
        cx={circleXY}
        cy={circleXY}
        r={radius}
      />
    </svg>
  )
}
